



































import Vue from 'vue';
import Component from 'vue-class-component';
import { blocklistRecordsModule } from '@/store';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import TableNavigation from '@/components/tables/navigation.vue';
import { BlocklistRecordsModule } from '@/store/sessions/blocklist-records';
import AuthSessionsTabs from '@/components/sessions/sessions/auth.sessions.tabs.vue';

const schema: ITableColumn[] = [
  { label_i18n: 'id', prop: 'id', 'class-name': 'events-table__id-cell', width: 80 },
  { label_i18n: 'uuid', prop: 'uuid', width: 160 },
  { label_i18n: 'reason', prop: 'reason', 'min-width': 200 },
  { label_i18n: 'created', prop: 'created_date', component: TableComponentNames.DateTime, width: 160, sortable: true },
  { label_i18n: 'expire', prop: 'expire_date', component: TableComponentNames.DateTime, width: 160 }
];

@Component({
  name: 'blocklist-records-list',
  components: { AuthSessionsTabs, TableNavigation, PageLayout, TableGenerator }
})
export default class BlocklistRecordsList extends Vue {
  get state(): BlocklistRecordsModule {
    return blocklistRecordsModule;
  }

  get schema(): ITableColumn[] {
    return schema;
  }

  mounted() {
    blocklistRecordsModule.get();
  }

  rowClickHandler({ item }) {
    this.showBlockDialog(item);
  }

  createHandler() {
    this.showBlockDialog(null);
  }

  async deleteHandler() {
    for (let item of this.state.selectedItems) {
      try {
        await this.state.delete(item.id);
      } catch (e) {
        console.warn('delete.error ', e);
      }
    }
    await this.state.get();
  }

  showBlockDialog(item) {
    this.$store.state.dialog.blocklist_record.item = item;
    this.$store.state.dialog.blocklist_record.enabled = true;
  }
}
